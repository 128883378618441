.m-meditation-card {
  align-items: center;
  display: flex;
  gap: var(--space-1);
  justify-content: space-between;
  text-align: center;
}

.m-meditation-card > :not(:where(:first-child, :last-child)) {
  flex-grow: 1;
}
