.m-event-card {
  align-items: center;
  display: flex;
  gap: var(--space-1);
  justify-content: space-between;
  text-align: center;
}

.m-event-card
  > :not(:where(.m-event-card__icon, :last-child:not(:nth-child(2)))) {
  flex-grow: 1;
}

.m-event-card__icon {
  color: var(--color);
}
