.m-mood-card__exploration {
  /* Display is set to flex by EventCard parent component.
  Setting flex-basis to 100% means this will try to take up
  as much horizontal space as possible*/
  flex-basis: 100% !important;
  grid-column-start: 2;
  line-height: 1;
  margin: 0;
  word-break: break-word;
}

.m-mood-card__heading {
  line-height: 1;
  margin: 0;
}

.m-mood-card__time {
  line-height: 1;
  margin-bottom: 0;
}
